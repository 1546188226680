import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import Cookies from 'js-cookie';
import db from "../Firebase";
import Navbar from './Navbar';
import EmptyCart from './EmptyCart';
import { Link } from 'react-router-dom';
import Footer from './Footer';
import axios from 'axios';
import moment from 'moment';
import Facebook from './FacebookPixel';
import { addCartData } from '../query';
import { DateTimeUtil } from '../Utility';

const Cart = () => {

    const { user } = useContext(AuthContext);
    const [cartItems, setCartItems] = useState([]);
    const navigate = useNavigate();
    const [totalAmount, setTotalAmount] = useState(0);
    const [isSubmit, setIsSubmit] = useState(false);
    const [isUserData, setIsUserData] = useState(false);
    const [customerData, setCustomerData] = useState({});
    const [walletBalance, setWalletBalance] = useState(0);
    const [custID, setCustID] = useState('');

    const calculateTotalOrderAmount = () => {
        let total_amount = 0;
        cartItems.forEach(item => {
            if (item.subscriptionType == "Custom") {
                total_amount += item.price * item.monday;
                total_amount += item.price * item.tuesday;
                total_amount += item.price * item.wednesday;
                total_amount += item.price * item.thursday;
                total_amount += item.price * item.friday;
                total_amount += item.price * item.saturday;
                total_amount += item.price * item.sunday;

            } else {
                total_amount += item.price * item.quantity;
            }

        });
        setTotalAmount(total_amount);
    };

    const getDeliveryDate = () => {
        const now = moment();
        let deliveryDate;

        if (now.hour() >= 23) {
            deliveryDate = now.add(2, 'days');
        } else {
            deliveryDate = now.add(1, 'day');
        }
        return deliveryDate.format('MMMM Do, YYYY');
    }

    const deliveryDate = getDeliveryDate();

    useEffect(() => {
        const storedCart = JSON.parse(localStorage.getItem('cart')) || [];
        setCartItems(storedCart);

        const cookieValue = Cookies.get('user');

        if (cookieValue) {
            const parsedValue = JSON.parse(cookieValue);
            const phoneNumber = parsedValue.phone;

            const fetchCustomerData = async () => {
                try {
                    const customersRef = db.collection('customers_data');
                    const querySnapshot = await customersRef.where('customer_phone', '==', phoneNumber).get();


                    if (!querySnapshot.empty) {
                        setIsUserData(true);
                        const doc = querySnapshot.docs[0];
                        const data = doc.data();
                        setCustomerData(data);
                        setCustID(doc.id);


                        
                        const unsubscribe = doc.ref.onSnapshot((docSnapshot) => {
                            if (docSnapshot.exists) {
                                const updatedData = docSnapshot.data();
                                setWalletBalance(updatedData.wallet_balance);
                            }
                        });

                        
                        return () => unsubscribe();
                    } else {
                        setIsUserData(false);
                    }
                } catch (error) {
                    console.error('Error fetching customer data:', error);
                    
                }
            };

            fetchCustomerData();
        } else {
            
            
        }
    }, []);

    useEffect(() => {
        calculateTotalOrderAmount();
    }, [cartItems]);

    const handleRemoveFromCart = (index) => {
        const newCartItems = [...cartItems];
        newCartItems.splice(index, 1);
        setCartItems(newCartItems);
        localStorage.setItem('cart', JSON.stringify(newCartItems));
        calculateTotalOrderAmount();
        updateCart();
    };

    const updateCart = ()=>{
      let phone = Cookies.get("user")
      const cart = JSON.parse(localStorage.getItem('cart')) || [];
      phone = phone?.substring(phone.length-12, phone.length-2)|| "";
      addCartData(phone, cart);
    
    }
    const handleStartDateChange = (index, newDate) => {
        const updatedCartItems = [...cartItems];
        updatedCartItems[index].startDate = newDate; // Update the start date
        setCartItems(updatedCartItems); // Update state
      };
      
    const handleCheckout = () => {

        const utm_source = localStorage.getItem('utm_source');

        if(utm_source) {

            <Facebook title={"Click on checkout"}/>
        }

        if (user) {
            navigate('/');
        } else {
            navigate('/login');
        }
    };

    function generateCustomerId() {
        const now = new Date();
        const timestamp = now.getTime(); 
        const random4Digits = Math.floor(Math.random() * 10000).toString().padStart(4, '0'); 

        
        const customerId = (timestamp % 10000).toString().padStart(4, '0') + random4Digits;

        return customerId;
    }

    // const handleCheckoutConfirm = async () => {
    //     <Facebook title={"Click on checkout confirm"} />;
    //     if (customerData.empty) {
    //         navigate('/customer_details');
    //     } else {
    //         const totalAmount = cartItems.reduce((total, item) => total + item.price * item.quantity, 0);
    //         if (totalAmount > customerData.wallet_balance) {
    //             const remainingBalance = parseInt(totalAmount) - parseInt(customerData.wallet_balance);
    //             alert(`Your wallet balance is insufficient. Please recharge your wallet for an additional ${remainingBalance.toFixed(2)} balance.`);
    //             setIsSubmit(false);
    //             return;
    //         }
    //         setIsSubmit(true);
    //         try {
    //             const activities = [];
    //             const loggedInUserName = customerData.customer_name;
    //             for (const item of cartItems) {
    //                 const sId = generateCustomerId();
    //                 if (item.subscriptionType) {
    //                     const subscriptionData = {
    //                     coupon_code: "",
    //                     created_date: new Date(),
    //                     customer_address: customerData.customer_address, 
    //                     customer_id: customerData.customer_id, 
    //                     customer_name: customerData.customer_name,
    //                     customer_phone: customerData.customer_phone,
    //                     end_date: new Date(item.endDate),
    //                     friday: parseInt(item.friday),
    //                     hub_name: customerData.hub_name,
    //                     interval: parseInt(item.interval),
    //                     monday: parseInt(item.monday),
    //                     next_delivery_date: item.next_delivery_date,
    //                     package_unit: item.package_unit,
    //                     price: parseInt(item.price),
    //                     product_name: item.productName,
    //                     quantity: parseInt(item.quantity),
    //                     resume_date: new Date(item.startDate),
    //                     saturday: parseInt(item.saturday),
    //                     start_date: new Date(item.startDate),
    //                     status: "1",
    //                     subscription_id: sId,
    //                     subscription_type: item.subscriptionType,
    //                     sunday: parseInt(item.sunday),
    //                     thursday: parseInt(item.thursday),
    //                     tuesday: parseInt(item.tuesday),
    //                     updated_date: new Date(),
    //                     wednesday: parseInt(item.wednesday),
    //                     reason: "",
    //                     };
    
    //                     // Adding UTM parameters if available
    //                     ['utm_campaign', 'utm_medium', 'utm_source'].forEach(key => {
    //                         subscriptionData[key] = localStorage.getItem(key)
    //                             ? JSON.parse(localStorage.getItem(key)).value
    //                             : '';
    //                     });    
    //                     // Add subscription to Firestore
    //                     await db.collection('subscriptions_data').add(subscriptionData);
    //                     activities.push({
    //                         action: "New Subscription",
    //                         created_date: new Date(),
    //                         customer_id: customerData.customer_id,
    //                         customer_name: loggedInUserName,
    //                         customer_phone: customerData.customer_phone,
    //                         description: `Subscription created with ID: ${sId} for product: ${item.productName} by ${loggedInUserName}`,
    //                         object: "Subscription",
    //                         platform: "webApp",
    //                     });
    //                 } else {
    //                     const orderData = {
    //                         created_date: new Date(),
    //                         customer_address: customerData.customer_address,
    //                         customer_id: customerData.customer_id,
    //                         customer_name: loggedInUserName,
    //                         customer_phone: customerData.customer_phone,
    //                         hub_name: customerData.hub_name,
    //                         product_name: item.productName,
    //                         quantity: parseInt(item.quantity),
    //                         price: parseInt(item.price),
    //                         status: "1",
    //                         delivery_date: item.delivery_date || new Date(),
    //                         created_by: loggedInUserName, // Added field for creator
    //                     };
    //                     // Add one-time order to Firestore
    //                     await db.collection('orders_data').add(orderData);
    
    //                     activities.push({
    //                         action: "One-Time Order",
    //                         created_date: new Date(),
    //                         customer_id: customerData.customer_id,
    //                         customer_name: loggedInUserName,
    //                         customer_phone: customerData.customer_phone,
    //                         description: `One-time order created for product: ${item.productName} by ${loggedInUserName}`,
    //                         object: "Order",
    //                         platform: "webApp",
    //                     });
    //                 }
    //             }
    //             // Log activities to `customer_activities`
    //             for (const activity of activities) {
    //                 await db.collection('customer_activities').add(activity);
    //             }
    //             // Clear cart and navigate to success page
    //             setCartItems([]);
    //             localStorage.removeItem('cart');
    //             updateCart();
    //             setTotalAmount(0);
    //             navigate('/order-success');
    //             setIsSubmit(false);
    //         } catch (error) {
    //             console.error('Error processing checkout:', error);
    //             setIsSubmit(false);
    //         }
    //     }
    // };
    

    const handleCheckoutConfirm = async () => {
        <Facebook title={"Click on checkout confirm"} />;
        
        if (customerData.empty) {
            navigate('/customer_details');
        } else {
            const today = new Date();
            today.setHours(0, 0, 0, 0); 
            const tomorrow = new Date(today);
            tomorrow.setDate(tomorrow.getDate() + 1);
            // Validate start dates
            for (const item of cartItems) {
                const startDate = new Date(item.startDate);
            startDate.setHours(0, 0, 0, 0); 
           
            if (startDate < tomorrow) {
                alert(`The start date for ${item.productName} could not be earlier than tomorrow. Please update your start date.`);
                return;
            }
            }
           
            const totalAmount = cartItems.reduce((total, item) => total + item.price * item.quantity, 0);
            if (totalAmount > customerData.wallet_balance) {
                const remainingBalance = parseInt(totalAmount) - parseInt(customerData.wallet_balance);
                alert(`Your wallet balance is insufficient. Please recharge your wallet for an additional ${remainingBalance.toFixed(2)} balance.`);
                setIsSubmit(false);
                return;
            }
    
            setIsSubmit(true);
            try {
                const activities = [];
                const loggedInUserName = customerData.customer_name;
                for (const item of cartItems) {
                    const sId = generateCustomerId();
                    if (item.subscriptionType) {
                        const subscriptionData = {
                            coupon_code: "",
                            created_date: new Date(),
                            customer_address: customerData.customer_address,
                            customer_id: customerData.customer_id,
                            customer_name: customerData.customer_name,
                            customer_phone: customerData.customer_phone,
                            end_date: new Date(item.endDate),
                            friday: parseInt(item.friday),
                            hub_name: customerData.hub_name,
                            interval: parseInt(item.interval),
                            monday: parseInt(item.monday),
                            next_delivery_date: item.next_delivery_date,
                            package_unit: item.package_unit,
                            price: parseInt(item.price),
                            product_name: item.productName,
                            quantity: parseInt(item.quantity),
                            resume_date: new Date(item.startDate),
                            saturday: parseInt(item.saturday),
                            start_date: new Date(item.startDate),
                            status: "1",
                            subscription_id: sId,
                            subscription_type: item.subscriptionType,
                            sunday: parseInt(item.sunday),
                            thursday: parseInt(item.thursday),
                            tuesday: parseInt(item.tuesday),
                            updated_date: new Date(),
                            wednesday: parseInt(item.wednesday),
                            reason: "",
                        };
    
                        // Adding UTM parameters if available
                        ['utm_campaign', 'utm_medium', 'utm_source'].forEach(key => {
                            subscriptionData[key] = localStorage.getItem(key)
                                ? JSON.parse(localStorage.getItem(key)).value
                                : '';
                        });
    
                        // Add subscription to Firestore
                        await db.collection('subscriptions_data').add(subscriptionData);
                        activities.push({
                            action: "New Subscription",
                            created_date: new Date(),
                            customer_id: customerData.customer_id,
                            customer_name: loggedInUserName,
                            customer_phone: customerData.customer_phone,
                            description: `Subscription created with ID: ${sId} for product: ${item.productName} by ${loggedInUserName}`,
                            object: "Subscription",
                            platform: "webApp",
                        });
                    } else {
                        const orderData = {
                            created_date: new Date(),
                            customer_address: customerData.customer_address,
                            customer_id: customerData.customer_id,
                            customer_name: loggedInUserName,
                            customer_phone: customerData.customer_phone,
                            hub_name: customerData.hub_name,
                            product_name: item.productName,
                            quantity: parseInt(item.quantity),
                            price: parseInt(item.price),
                            status: "1",
                            delivery_date: item.delivery_date || new Date(),
                            created_by: loggedInUserName, // Added field for creator
                        };
                        // Add one-time order to Firestore
                        await db.collection('orders_data').add(orderData);
    
                        activities.push({
                            action: "One-Time Order",
                            created_date: new Date(),
                            customer_id: customerData.customer_id,
                            customer_name: loggedInUserName,
                            customer_phone: customerData.customer_phone,
                            description: `One-time order created for product: ${item.productName} by ${loggedInUserName}`,
                            object: "Order",
                            platform: "webApp",
                        });
                    }
                }
                // Log activities to `customer_activities`
                for (const activity of activities) {
                    await db.collection('customer_activities').add(activity);
                }
                // Clear cart and navigate to success page
                setCartItems([]);
                localStorage.removeItem('cart');
                updateCart();
                setTotalAmount(0);
                navigate('/order-success');
                setIsSubmit(false);
            } catch (error) {
                console.error('Error processing checkout:', error);
                setIsSubmit(false);
            }
        }
    };
    
    const editAddress = () => {
        
        navigate('/profile')
    }

    const [rechargeAmount, setRechargeAmount] = useState('');
    
    const handleRecharge = async () => {
        // Check if recharge amount is valid
        if (!rechargeAmount || rechargeAmount === "undefined" || isNaN(parseFloat(rechargeAmount))) {
            alert("Please enter a valid amount to proceed");
            return;
        }
    
        const productinfo = "dairy products";
        const data = {
            amount: parseFloat(rechargeAmount), 
            productInfo: productinfo,
            firstname: customerData?.customer_name || "Anonymous", 
            email: customerData?.customer_email || "no-email@domain.com", 
            phone: customerData?.customer_phone || "0000000000", 
        };
    
        try {
            console.log('Sending payment data:', data); 
    
            const response = await axios.post('https://whytefarms.com/api/payment', data, {
                headers: {
                    'Content-Type': 'application/json',
                },
                timeout: 10000, // Optional timeout to avoid infinite loading
            });
    
            const { payUData, payuURL } = response.data;
    
            if (!payUData || !payuURL) {
                console.error('Invalid response from server:', response.data);
                alert('Failed to initiate payment. Please try again.');
                return;
            }

            // Create and submit a form dynamically
            const form = document.createElement('form');
            form.action = payuURL;
            form.method = 'POST';
    
            Object.keys(payUData).forEach((key) => {
                const input = document.createElement('input');
                input.type = 'hidden';
                input.name = key;
                input.value = payUData[key];
                form.appendChild(input);
            });
    
            document.body.appendChild(form);
            form.submit();
        } catch (error) {
            console.error('Payment Error:', error);
    
            // Enhanced error handling
            if (error.response) {
                // Server responded with a status code outside 2xx
                console.error('Error response data:', error.response.data);
                alert(`Payment initiation failed: ${error.response.data?.message || 'Server error'}`);
            } else if (error.request) {
                // No response received
                console.error('No response from server:', error.request);
                alert('Failed to connect to the payment server. Please check your network.');
            } else {
                // Other errors
                console.error('Error:', error.message);
                alert('An unexpected error occurred. Please try again later.');
            }
        }
    };
    
    const generateWhatsAppMessage = () => {
        let message = 'Hey! I’d like to place an order for the following products:\n';
        cartItems.forEach((item, index) => {
            message += `${index + 1}. ${item.productName} - ${item.quantity} x ₹${item.price}\n`;
        });
        message += `Total Amount: ₹${totalAmount.toFixed(2)}`;
        return encodeURIComponent(message);
    };

    const whatsappURL = `https://wa.me/919873710709?text=${generateWhatsAppMessage()}`;

    return (
        <>
            <Navbar />
            <div class="header-details" style={{ height: '100px', background: 'black' }}>

            </div>
            <div className="cart-section mt-150 mb-150">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-md-12">
                            {cartItems.length === 0 ? (
                                <EmptyCart />
                            ) : (
                                <div
                                    class="Subscription-section section theme-color"
                                    id="Subscription"
                                >
                                    My Cart
                                    <div class="table-section">
                                        <br />

                                        <table className="table table-hover">
                                            <thead className="table-light">

                                                <tr className="table-head-row">
                                                    <th className="product-remove"></th>
                                                    <th className="product-image">Product Image</th>
                                                    <th className="product-name">Name</th>
                                                    <th className="product-name">Order Type</th>
                                                    <th className="product-name">start date</th>
                                                    <th className="product-quantity">Quantity</th>
                                                    <th className="product-total">Price</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {cartItems.map((item, index) => (
                                                    <tr className="table-body-row" key={index}>
                                                        <td className="product-remove">
                                                            <i style={{ marginTop: '12px' }} class="fa-solid fa-trash" onClick={() => handleRemoveFromCart(index)}></i>
                                                        </td>
                                                        <td className="product-image">
                                                            <img src={item.image || "https://via.placeholder.com/150"} alt={item.productName} />
                                                        </td>
                                                        <td className="product-name">{item.productName}</td>
                                                        <td className="product-name">{item.subscriptionType}</td>
                                                        <td className="product-name">
                                                        <input
                                                        type="date"
                                                        value={new Date(item.startDate).toISOString().split('T')[0]}
                                                        onChange={(e) => handleStartDateChange(index, e.target.value)}
                                                        min={new Date().toISOString().split('T')[0]} // Prevent past dates
                                                        className="date-picker"
                                                    />
                                                                                                        </td>
                                                        {item.subscriptionType != 'Custom' ? <td className="product-quantity" style={{ textAlign: 'center' }}>{item.quantity}</td> :
                                                            <td className="product-quantity" style={{ textAlign: 'center' }}>-</td>
                                                        }
                                                        {item.subscriptionType != 'Custom' ? <td className="product-total" style={{ textAlign: 'center' }}>₹ {item.price * item.quantity}</td> :
                                                            <td className="product-total" style={{ textAlign: 'center' }}>₹ {item.price}</td>
                                                        }

                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                    <br />
                                    {user ? <div
                                        class="Subscription-section-new section theme-color"
                                        id="Subscription">

                                        <h6>Delivering To :

                                            <i style={{ marginLeft: '2rem', cursor: 'pointer' }} class="fas fa-edit" onClick={editAddress}></i>
                                        </h6>
                                        <br />
                                        <h6>{customerData.customer_address} {customerData.location}</h6>
                                    </div> : <></>}
                                </div>



                            )}
                        </div>
                        {cartItems.length > 0 ? <>
                            <div
                                class="Subscription-section section theme-color col-lg-4"
                                id="Subscription"
                            >
                                Order Summary
                                <div class="table-section">
                                    <br />
                                    <table className="table table-hover">
                                        <thead className="total-table-head">
                                            <tr className="table-total-row">
                                                <th>Total</th>
                                                <th>Price</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr className="total-data">
                                                <td><strong>Subtotal: </strong></td>
                                                <td>₹ {totalAmount}</td>
                                            </tr>
                                            <tr className="total-data">
                                                <td><strong>Handling charges: </strong></td>
                                                <td>₹ 0</td>
                                            </tr>
                                            <tr className="total-data">
                                                <td><strong>Total: </strong></td>
                                                <td>₹ {totalAmount}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    {cartItems.length > 0 && (
                                        <div className="text-center mt-4">
                                            {user ? (
                                                <>
                                                    {isSubmit ? (
                                                        <button className="btn btn-primary">Please Wait...</button>
                                                    ) : (
                                                        <>
                                                            <button onClick={handleCheckoutConfirm} className="subscribe-button">Confirm Order</button>
                                                            {deliveryDate && (<p className='mt-4' style={{fontWeight: '600', fontSize: '15px'}}>Your order will be delivered on {deliveryDate}</p>)}
                                                        </>
                                                    )}
                                                </>
                                            ) : (
                                                <button onClick={handleCheckout} className="btn btn-primary" style={{ background: '#d2ab67', borderRadius: '50px', border: '1px solid #d2ab67', height: '42px', fontWeight: '600' }}>Checkout</button>
                                            )}
                                            <br />
                                            <br />
                                            {customerData.wallet_balance < totalAmount && (
                                                <div>
                                                    <p style={{ color: "#dc3545", marginBottom: "1rem" }}>You don't have enough balance. Please recharge your wallet for the remaining {(totalAmount - customerData.wallet_balance).toFixed(2)} balance.</p>
                                                    <div>
                                                        <input
                                                            style={{ marginRight: "1rem", width: "215px" }}
                                                            type="number"
                                                            placeholder="Enter amount to recharge"
                                                            value={rechargeAmount}
                                                            onChange={(e) => setRechargeAmount(e.target.value)}
                                                            min="1"
                                                        /></div>
                                                    <div style={{ display: "flex", justifyContent: "space-between", margin: '10px;' }}>
                                                        <button style={{ background: "#d2ab67", borderRadius: "50px", padding: "10px 20px", color: "white", height: "3rem" }} onClick={handleRecharge} className="btn recharge-btn">Recharge</button>
                                                        {customerData.wallet_balance < totalAmount && (
                                                            <button className="subscribe-button" style={{ background: "#d2ab67", borderRadius: "50px", padding: "10px 20px", color: "white", height: "3rem" }}> <a href={whatsappURL} class="text-light">
                                                                Cash on Delivery
                                                            </a></button>
                                                        )}
                                                    </div>
                                                </div>
                                            )}

                                            <br />
                                            <Link to="/" style={{ color: 'black', fontWeight: 'bold', color: '#e1a95f', textDecoration: 'none' }}>
                                                <span >Continue Shopping</span>
                                            </Link>
                                        </div>
                                    )}

                                </div>
                            </div></> : <></>
                        }
                    </div>
                </div>
                <br />
                <Footer />
            </div>
        </>
    );
};

export default Cart;

