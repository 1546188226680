import Swal from 'sweetalert2';
import { DateTimeUtil } from './Utility';
import db from './Firebase';

const Default_Limit = 3;
const cartDB = "cart_data";
const productDB = "products_data";
const customerDB = "customers_data";
const Toast = Swal.mixin({
  toast: true,
  background: '#69aba6',
  position: 'top-end',
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer);
    toast.addEventListener('mouseleave', Swal.resumeTimer);
  }
});

const showToast = (icon, title) => {
  Toast.fire({
    icon,
    title
  });
};





const addCartData = (customer_phone, data) => {
  return fetchCustomerDetails(customer_phone)
    .then((customerData) => {
      if (customerData.error) {
        console.log(customerData.error);
        showToast('error', `Error: ${customerData.error}`);
        return Promise.reject({ error: customerData.error });
      }

      const finalData = prepareData(data);
      if (finalData.error) {
        console.log(finalData.error);
        showToast('error', `Error: ${finalData.error}`);
        return Promise.reject(finalData);
      }

      return db.collection(cartDB)
        .where("customer_phone", "==", customerData.customer_phone)
        .get()
        .then((querySnapshot) => {
          const cart_data = querySnapshot.docs.map((doc) => ({
            id: doc.id,
            data: doc.data(),
          }));
          const val = {...customerData,
            ...finalData}
          if (cart_data.length > 0) {
            return db.collection(cartDB).doc(cart_data[0].id).update({
              ...cart_data.data, ...finalData 
            }).then(() => {
              showToast('success', 'Cart Updated Successfully');
              return cart_data;
            });
          } else {
            return db.collection(cartDB).add({
              ...customerData, ...finalData 
            }).then(() => {
              showToast('success', 'Cart Added Successfully');
              return cart_data;
            });
          }
        })
        .catch((error) => {
          console.log("Error fetching or updating cart: ", error);
          showToast('error', 'An error occurred while updating the cart.');
          return Promise.reject({ error: "An error occurred while updating the cart." });
        });
    })
    .catch((error) => {
      console.log("Error preparing data: ", error);
      showToast('error', 'please Login first');
      return { error: "An error occurred while preparing the data." };
    })
    .finally(() => {
      // console.log("Fetch cart data complete...");
    });
};

const prepareData = (data) => {
  const requiredProductFields = ["product_id", "product", "product_name", "quantity"];
  let isValid = true;
  let error = null;

  if (isValid && Array.isArray(data)) {
    for (let i = 0; i < data.length; i++) {
      const product = data[i];
      for (let prop of requiredProductFields) {
        if (!(prop in product)) {
          error = `${prop} is missing in product data at index ${i + 1}`;
          isValid = false;
          break;
        }
      }
      if (!isValid) break;
    }
  } else {
    error = "products array is missing or empty";
    isValid = false;
  }

  if (!isValid) {
    return { error };
  }

  const finalData = {
    update_date: DateTimeUtil.timestampToISODate(new Date()),
    update_timestamp: new Date(),
    // products: data.map(product => ({
    //   product_id: product.product_id || "",
    //   product: product.product || "",
    //   product_name: product.product_name || "",
    //   quantity: product.quantity || 0,
    // })),
    products: data
  };
  console.log(finalData);
  return finalData;
};

// const fetchCustomerDetails = async (phone) => {
//   console.log(phone, phone.length)
//   if (!phone || phone.length !== 10 || isNaN(Number(phone))) {
//     return { error: "Invalid phone number" };
//   }

//   try {
//     const querySnapshot = await db.collection("customers_data")
//       .where("customer_phone", "==", phone)
//       .get();

//     if (querySnapshot.empty) {
//       return { error: "Customer not found" };
//     }

//     const customer = querySnapshot.docs[0].data();
//     return {
//       customer_id: customer.customer_id,
//       customer_name: customer.customer_name || "",
//       customer_phone: customer.customer_phone,
//     };
//   } catch (error) {
//     console.error("Error fetching customer details:", error);
//     return { error: "Error fetching customer details" };
//   } finally {
//     console.log("Customer detail fetch complete");
//   }
// };

const fetchCustomerDetails = (phone) => {
  console.log(phone, phone.length);
  
  if (!phone || phone.length !== 10 || isNaN(Number(phone))) {
    return Promise.resolve({ error: "Invalid phone number" });
  }

  return db.collection(customerDB)
    .where("customer_phone", "==", phone)
    .get()
    .then((querySnapshot) => {
      if (querySnapshot.empty) {
        return { error: "Customer not found" };
      }

      const customer = querySnapshot.docs[0].data();
      return {
        customer_id: customer.customer_id,
        customer_name: customer.customer_name || "",
        customer_phone: customer.customer_phone,
      };
    })
    .catch((error) => {
      console.error("Error fetching customer details:", error);
      return { error: "Error fetching customer details" };
    })
    .finally(() => {
      console.log("Customer detail fetch complete");
    });
};
export { addCartData, prepareData };
