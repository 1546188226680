import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import db from "../Firebase";
import "./profile.css";
import Form from "react-bootstrap/Form";
import Cookies from "js-cookie";
import Moment from "moment";
import { extendMoment } from "moment-range";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Navbar from "./Navbar";
import CryptoJS from 'crypto-js';
import Footer from "./Footer";
import Dialog from "./Dialog";
import { collection, getDocs, query, where, getCountFromServer } from 'firebase/firestore';
import CustomerDetails from "./CustomerDetails";
import Swal from 'sweetalert2';
import axios from 'axios';
import AddVacationDialog from "./AddVacationDialog";

function Profile() {
  const moment = extendMoment(Moment);
  const [loadDeliveryPref, setLoadDelieveryPref] = useState(false);
  const [loadProfile, setLoadProfile] = useState(true);
  const [loadSubs, setLoadSubs] = useState(false);
  const [loadOrders, setLoadOrders] = useState(false);
  const [loadWallet, setLoadWallet] = useState(false);
  const [loadCalendar, setLoadCalendar] = useState(false);
  const [walletHistory, setWalletHistory] = useState([]);
  const [visibleRecords, setVisibleRecords] = useState(10);
  const [visibleOrders, setVisibleOrders] = useState(10);
  const [amount, setAmount] = useState("");
  const [email, setEmail] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("Online");
  const navigate = useNavigate();
  const [hubs, setHubs] = useState([]);
  const [locations, setLocations] = useState([]);
  const [subscriptions, setSubscriptions] = useState([]);
  const [advanceOrders, setAdvanceOrders] = useState([]);
  const [subscriptionCount, setSubscriptionCount] = useState(0);
  const [ordersCount, setOrdersCount] = useState(0);
  const [vacationCount, setVacationCount] = useState(0);
  const [edit, setEdit] = useState(false);
  const [customerDetails, setCustomerDetails] = useState({
    id: "", 
    customer_name: "",
    alt_phone: "",
    flat_villa_no: "",
    floor: "",
    customer_phone: "",
    customer_address: "",
    customer_type: false,
    city: "",
    state: "",
    pincode: "",
    hub_name: "",
    location: "",
    delivery_exe_id: "",
  });

  const [orderHistory, setOrderHistory] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [deliveryExecutive, setDeliveryExecutive] = useState(null);
  const [deliveryPreference, setDeliveryPreference] = useState("");
  const [futureDate, setFutureDate] = useState(new Date());
  const [selectedSub, setSelectedSub] = useState();
  const [newQuantity, setNewQuantity] = useState("");
  const [updateAdvanceOrder, setUpdateAdvanceOrder] = useState(false);
  const [vacationData, setVacationData] = useState([]);
  const [advancedOrderID, setAdvancedOrderID] = useState({});
  const [loading, setLoading] = useState(false);
  const [loadVacations, setLoadVacations] = useState(false);



  useEffect(() => {
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);
        
    const cookieValue = Cookies.get("user");
    if (cookieValue) {
      const parsedValue = JSON.parse(cookieValue);
      const phoneNumber = parsedValue.phone;

      const fetchCustomerData = async () => {
        try {
          const customersRef = db.collection("customers_data");
          const querySnapshot = await customersRef
            .where("customer_phone", "==", phoneNumber)
            .get();

          if (!querySnapshot.empty) {
            const doc = querySnapshot.docs[0];
            const data = doc.data();
            setCustomerDetails({ ...data, id: doc.id });
            fetchLocations(data.hub_name);
            fetchDeliveryExecutiveId(data.location);
            fetchSubscriptionCount(data.customer_id);
            fetchOrdersCount(data.customer_id);
            fetchSubscriptions(data.customer_id);
            fetchDeliveryExecutive(data.delivery_exe_id);
            fetchVacationsCount(data.customer_id);
            
          } else {

          }
        } catch (error) {
          console.error("Error fetching customer data:", error);
        }
      };
      fetchCustomerData();
    } else {
      navigate("/login");
    }
  }, [navigate]);


  const fetchVacationsCount = async (customerId) => {
    try {
      const collRef = collection(db, 'customers_vacation');
      const q = query(collRef, where('customer_id', '==', customerId))
      const snapshot = await getCountFromServer(q);

      setVacationCount(snapshot.data().count);

    } catch (error) {
      console.error("Error fetching vacation count: ", error);
    }
  };

  const reloadCustomerData = async (phoneNumber) => {
    try {
      const customersRef = db.collection("customers_data");
      const querySnapshot = await customersRef
        .where("customer_phone", "==", phoneNumber)
        .get();

      if (!querySnapshot.empty) {
        const doc = querySnapshot.docs[0];
        const data = doc.data();
        setCustomerDetails({ ...data, id: doc.id });
      }
    } catch (error) {
      console.error("Error fetching customer data:", error);
    }
  };



  const advOrd = (customerId) => {
    setAdvancedOrderID({});
    db.collection("bulk_update_quantity").where("customer_id", "==", customerId).onSnapshot((snapshot) => {
      const aOIDData = snapshot.docs.map(doc => {
        const data = doc.data();

        const { date, product_name, quantity } = data;

        const formattedDate = moment(date.toDate()).format('YYYY-MM-DD');
        const key = `${product_name}-${formattedDate}`;
        return { [key]: quantity };
      });

      
      setAdvancedOrderID(Object.assign({}, ...aOIDData));
    });
  };

  const fetchDeliveryPreference = async (customerId) => {
    try {
      const preferenceQuery = await db
        .collection("delivery_preference")
        .where("customer_id", "==", customerId)
        .get();

      if (!preferenceQuery.empty) {
        const preferenceData = preferenceQuery.docs[0].data();
        setDeliveryPreference(preferenceData.delivery_mode);
      }
    } catch (error) {
      console.error("Error fetching delivery preference:", error);
    }
  };

  const fetchDeliveryExecutive = async (id) => {
    try {
      const querySnapshot = await db
        .collection("hubs_users_data")
        .where("hub_user_id", "==", id)
        .get();

      if (!querySnapshot.empty) {
        const doc = querySnapshot.docs[0];
        setDeliveryExecutive(doc.data());
      } else {
        console.error("No such delivery executive!");
      }
    } catch (error) {
      console.error("Error fetching delivery executive: ", error);
    }
  };

  const fetchOrderHistory = async (customerId) => {
    const ordersRef = db
      .collection("order_history")
      .where("customer_id", "==", customerId)
    

    const snapshot = await ordersRef.get();

    const orders = snapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    setOrderHistory(orders);

    
    const uniqueOrders = orders
      .reduce((acc, current) => {
        const x = acc.find((item) => item.order_id === current.order_id);
        if (!x) {
          return acc.concat([current]);
        }
        return acc;
      }, [])
      .sort((a, b) => b.created_date.seconds - a.created_date.seconds);

    setUniqueOrders(uniqueOrders);
  };

  const handleOrderClick = (order) => {
    const products = orderHistory
      .filter((o) => o.order_id === order.order_id)
      .map((o) => ({
        name: o.product_name,
        quantity: o.quantity,
        package_unit: o.package_unit,
        price: o.price,
        image: o.image, 
      }));

    setSelectedOrder({
      ...order,
      products,
    });
  };

  const fetchWalletHistory = async (customerId) => {
    try {
      const walletHistoryRef = db.collection("wallet_history");
      const querySnapshot = await walletHistoryRef
        .where("customer_id", "==", customerId)
        .orderBy('created_date', 'desc')
        .get();
      const history = querySnapshot.docs.map((doc) => doc.data());
      setWalletHistory(history);
    } catch (error) {
      console.error("Error fetching wallet history:", error);
    }
  };

  const loadMore = () => {
    setVisibleRecords(prev => prev + 10); 
  };

  const loadMoreOrders = () => {
    setVisibleOrders(prev => prev + 10); 
  };

  const fetchVacations = async (customerId) => {
    try {
      db.collection("customers_vacation").where('customer_id', '==', customerId).orderBy("updated_date", "desc").onSnapshot((snapshot) => {
        setVacationData(
          snapshot.docs.map((doc) => ({
            id: doc.id,
            data: doc.data(),
          }))
        );
      });
    } catch (error) {
      console.error("Error fetching customers vacations:", error);
    }
  };

  const fetchSubscriptionCount = async (customerId) => {
    try {
      const subscriptionsRef = db.collection("subscriptions_data");
      const querySnapshot = await subscriptionsRef
        .where("customer_id", "==", customerId)
        .get();
      setSubscriptionCount(querySnapshot.size);
    } catch (error) {
      console.error("Error fetching subscription count:", error);
    }
  };




  const fetchSubscriptions = async (customerId) => {
    try {
      const subscriptionsRef = db.collection("subscriptions_data");
      const querySnapshot = await subscriptionsRef
        .where("customer_id", "==", customerId)
        .get();
      const subscriptionsList = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setSubscriptions(subscriptionsList);

      
    } catch (error) {
      console.error("Error fetching subscriptions:", error);
    }
  };

  const isCustomerOnVacation = async (date) => {
    
    date.setHours(0, 0, 0, 0);
    const endDate = new Date(date);
    endDate.setHours(23, 59, 59, 999);



    try {
      const collRef = collection(db, 'customers_vacation');
      const q = query(collRef, where('customer_id', '==', customerDetails.customer_id)
        , where('start_date', '<=', endDate)
        , where('end_date', '>=', date))

      const snapshot = await getCountFromServer(q);

      if (snapshot.data().count >= 1) {
        return true;
      } else {
        return false;
      }

    } catch (error) {
      console.error("Error fetching collection size: ", error);
    }

  }


  const calculateScheduledDates = (subscription) => {
    const deliveryDate = new Date(subscription.next_delivery_date);
    deliveryDate.setHours(0, 0, 0);
    const interval = parseInt(subscription.interval);
    const futureCheckDate = new Date(futureDate);
    futureCheckDate.setHours(0, 0, 0);
    if (isNaN(interval) || !deliveryDate || !futureCheckDate) {
      return false;
    }

    let currentDate = new Date(deliveryDate);
    while (currentDate <= futureCheckDate) {
      if (currentDate.getTime() === futureCheckDate.getTime()) {
        return true;
      }
      currentDate.setDate(currentDate.getDate() + interval);
    }
    return false;
  };



  const getBulkQuantity = async (futureDate, subscription) => {
    
    const futureDateEnd = new Date(futureDate);
    futureDateEnd.setHours(23, 59, 59, 999);

    try {
      const collRef = collection(db, 'bulk_update_quantity');
      const q = query(
        collRef,
        where('subscription_id', '==', subscription.subscription_id),
        where('delivery_date', '==', moment(futureDate).format('YYYY-MM-DD'))
        
      );

      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        const doc = querySnapshot.docs[0];
        const data = doc.data();
        return data.quantity;
      } else {

        return null;
      }
    } catch (error) {
      console.error("Error fetching bulk quantity: ", error);
      
    }
  };

  const getBulkQuantityForUpdate = async (futureDate, sid) => {
    const futureDateEnd = new Date(futureDate);
    futureDateEnd.setHours(23, 59, 59, 999);

    try {
      const collRef = collection(db, 'bulk_update_quantity');
      const q = query(
        collRef,
        where('subscription_id', '==', sid),
        where('date', '>=', futureDate),
        where('date', '<=', futureDateEnd)
      );

      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        const doc = querySnapshot.docs[0]; 
        const data = doc.data();
        return data.quantity;
      } else {

        return null; 
      }
    } catch (error) {
      console.error("Error fetching bulk quantity: ", error);
      
    }
  };

  const fetchAdvanceOrders = async (futureDate) => {
    setLoading(true);
    const subsList = [];
    setAdvanceOrders([]);
    const today = new Date();
    const weekdays = [
      "sunday",
      "monday",
      "tuesday",
      "wednesday",
      "thursday",
      "friday",
      "saturday",
    ];
    const isVacationOn = await isCustomerOnVacation(futureDate);

    
    if (!isVacationOn) {
      try {
        for (const subscription of subscriptions) {
          if (subscription.subscription_type === "One Time" && subscription.status == "1") {
            const selectedDate = moment(futureDate).format("YYYY-MM-DD");
            if (selectedDate === subscription.next_delivery_date) {
              const getBulk = await getBulkQuantity(futureDate, subscription);

              const updatedSubscription = { ...subscription, quantity: getBulk || subscription.quantity };
              subsList.push(updatedSubscription);
            }
          } else {
            if (subscription.subscription_type !== "Custom" && subscription.status == "1") {
              if (
                moment(futureDate).format("YYYY-MM-DD") ===
                subscription.next_delivery_date
              ) {
                const getBulk = await getBulkQuantity(futureDate, subscription);

                if (getBulk != null && getBulk >= 0) {
                  const updatedSubscription = { ...subscription, quantity: getBulk }
                  subsList.push(updatedSubscription);
                } else {
                  const updatedSubscription = { ...subscription, quantity: subscription.quantity };
                  subsList.push(updatedSubscription);
                }

              } else {
                const checkOrder = calculateScheduledDates(subscription);
                if (checkOrder) {

                  const getBulk = await getBulkQuantity(futureDate, subscription);
                  if (getBulk != null && getBulk >= 0) {
                    const updatedSubscription = { ...subscription, quantity: getBulk }
                    subsList.push(updatedSubscription);
                  } else {
                    const updatedSubscription = { ...subscription, quantity: subscription.quantity };
                    subsList.push(updatedSubscription);
                  }
                }
              }
            } else {
              const dayOfWeekIndex = futureDate.getDay();
              const dayOfWeek = weekdays[dayOfWeekIndex];

              
              if (subscription[dayOfWeek] > 0 && subscription.status == "1") {
                const getBulk = await getBulkQuantity(futureDate, subscription);
                if (getBulk != null && getBulk >= 0) {
                  const updatedSubscription = { ...subscription, quantity: getBulk }
                  subsList.push(updatedSubscription);
                } else {
                  const updatedSubscription = { ...subscription, quantity: subscription[dayOfWeek] };
                  subsList.push(updatedSubscription);
                }
              }
            }
          }
        }

        setAdvanceOrders(subsList);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching subscriptions:", error);
      }
    } else {
      setAdvanceOrders([]);
      Swal.fire("You have a running Vacation");
      setLoading(false);

    }

  };

  const fetchOrdersCount = async (customerId) => {
    try {
      const subscriptionsRef = db.collection("order_history");
      const querySnapshot = await subscriptionsRef
        .where("customer_id", "==", customerId)
        .get();
      setOrdersCount(querySnapshot.size);
    } catch (error) {
      console.error("Error fetching subscription count:", error);
    }
  };

  useEffect(() => {
    const fetchHubs = async () => {
      try {
        const hubCollection = db.collection("hubs_data");
        const hubSnapshot = await hubCollection.get();
        const hubList = hubSnapshot.docs.map((doc) => doc.data().hub_name);
        setHubs(hubList);
      } catch (error) {
        console.error("Error getting hubs:", error);
      }
    };
    fetchHubs();
  }, []);

  const fetchDeliveryExecutiveId = async (location) => {
    try {
      const locationCollection = db.collection("hubs_locations_data");
      const locationSnapshot = await locationCollection
        .where("location", "==", location)
        .where('hub_name', '==', customerDetails.hub_name)
        .get();
      const deliveryExecutiveId =
        locationSnapshot.docs[0]?.data().delivery_exe_id || "";
      setCustomerDetails((prevDetails) => ({
        ...prevDetails,
        delivery_exe_id: deliveryExecutiveId,
      }));
    } catch (error) {
      console.error("Error getting delivery executive id:", error);
    }
  };

  const fetchLocations = async (hub_name) => {
    try {
      setLoading(true);
      const locationCollection = db.collection("hubs_locations_data");
      const locationSnapshot = await locationCollection
        .where("hub_name", "==", hub_name)
        .get();
      const locationList = locationSnapshot.docs.map(
        (doc) => doc.data().location
      );
      setLocations(locationList);
    } catch (error) {
      console.error("Error getting locations:", error);
    }
    setLoading(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCustomerDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));

    if (name === "hub_name") {
      setCustomerDetails((prevDetails) => ({
        ...prevDetails,
        location: "",
      }));
      fetchLocations(value);
    }

    if (name === "location") {
      fetchDeliveryExecutiveId(value);
    }
  };

  const handleSave = async () => {
    try {

      const altPhone = customerDetails.alt_phone.trim();

      if (customerDetails.customer_name == undefined || customerDetails.customer_name == "") {
        alert("Please enter your name");
        return;
      } else if (customerDetails.customer_address == undefined || customerDetails.customer_address == "") {
        alert("Please enter address");
        return;
      } else if (customerDetails.hub_name == undefined || customerDetails.hub_name == "") {
        alert("Please select your hub");
        return;
      } else if (customerDetails.location == undefined || customerDetails.location == "") {
        alert("Please select your location");
        return;
      } else if (customerDetails.delivery_exe_id == undefined || customerDetails.delivery_exe_id == "") {
        alert("No delivery executive found for this address");
        return;
      } else if (altPhone.length !== 10 || !/^\d+$/.test(altPhone)) {
        alert('Alternate number must be exactly 10 digits and contain only numeric characters.');
        return;
      }
      const customersRef = db.collection("customers_data");
      await customersRef.doc(customerDetails.id).set(customerDetails);
      //alert("Profile updated successfully");
      setEdit(false);
      // Add Activity
      db.collection("customer_activities").add({
        'customer_phone': customerDetails.customer_phone,
        'customer_id': customerDetails.customer_id,
        'customer_name': customerDetails.customer_name,
        'customer_address': customerDetails.customer_name,
        'hub_name': customerDetails.hub_name,
        'delivery_exe_id': customerDetails.delivery_exe_id,
        'user': customerDetails.customer_name,
        'object': "Edit Customer",
        'action': "Edit Customer",
        'description': `Profile has been edited by ${customerDetails.customer_name} from webapp`,
        'platform':'webApp',
        'date': new Date(),
        'created_date': new Date()
      }).then(() => {

      });
      reloadCustomerData(customerDetails.customer_phone);
      fetchDeliveryExecutive(customerDetails.delivery_exe_id);
    } catch (error) {
      console.error("Error saving customer data:", error);
      // alert('Error updating profile');
    }
  };

  const loadscreen = (screenName) => {
    
    setLoadProfile(false);
    setLoadSubs(false);
    setLoadOrders(false);
    setLoadWallet(false);
    setLoadDelieveryPref(false);
    setLoadCalendar(false);
    setLoadVacations(false);

    if (screenName == "delivery_preference") {
      setLoadDelieveryPref(true);
      fetchDeliveryPreference(customerDetails.customer_id);
    } else if (screenName == "subscriptions") {
      setLoadSubs(true);
      setShowEditBulkQuantity(false);
      fetchSubscriptions(customerDetails.customer_id);
    } else if (screenName === "orders") {
      setLoadOrders(true);
      fetchOrderHistory(customerDetails.customer_id);
    } else if (screenName === "wallet") {
      setLoadWallet(true);
      fetchWalletHistory(customerDetails.customer_id);
    } else if (screenName === "profile") {
      setLoadProfile(true);
    } else if (screenName === "calendar") {
      const today = new Date();
      const tomorrow = new Date(today);
      tomorrow.setDate(tomorrow.getDate() + 1);
      setFutureDate(tomorrow);
      fetchAdvanceOrders(tomorrow);
      //advOrd(customerDetails.customer_id);
      setLoadCalendar(true);

    } else if (screenName === "vacations") {
      setLoadVacations(true);
      fetchVacations(customerDetails.customer_id);
    }
  };

  const handleQuickAmountSelect = (amount) => {
    setAmount(amount);
  };

  function generateCustomerId() {
    const now = new Date();
    const timestamp = now.getTime(); // Get the timestamp in milliseconds since January 1, 1970
    const random4Digits = Math.floor(Math.random() * 10000)
      .toString()
      .padStart(4, "0"); // Generate a random 4-digit number

    // Take the last 4 digits of the timestamp and concatenate with the random 4-digit number
    const customerId =
      (timestamp % 10000).toString().padStart(4, "0") + random4Digits;

    return customerId;
  }

  
  const subscriptionReasonUpdate = async () => {
    try {
      const subscriptionsRef = db.collection('subscriptions_data');
      
      // Query for subscriptions matching the customer ID
      const querySnapshot = await subscriptionsRef
        .where('customer_id', '==', customerDetails.customer_id)
        .get();
  
      const batch = db.batch();
      const activities = [];
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        
        if (data.reason === 'cron') {
          // If reason is 'cron', update status to '1'
          batch.update(doc.ref, { status: '1', reason: "" });
          activities.push({
            action: "Subscription Resumed",
            created_date: new Date(),
            customer_id: customerDetails.customer_id,
            customer_name: customerDetails.customer_name,
            customer_phone: customerDetails.customer_phone,
            description: `Subscription resumed for subscription Id: ${data.subscription_id} after wallet was recharged`,
            object: "Subscription Resumed",
            'platform':'webApp',
            user: ""
          });
        }
      });
  
      // Add all activities before committing the batch
      for (const activity of activities) {
        activity.platform = 'webApp'; // Add platform field
        await db.collection('customer_activities').add(activity);
      }
      
  
      // Commit the batch
      await batch.commit();
      console.log('Subscriptions updated successfully');
    } catch (error) {
      console.error('Error updating subscriptions:', error);
    }
  }

  const handleAddMoney = async () => {
    if (!amount) {
      alert("Please add amount first")
    } else {
      const productinfo = "dairy products";
      const data = {
        amount: parseInt(amount),
        productInfo: productinfo,
        firstname: customerDetails.customer_name,
        email: customerDetails.customer_email,
        phone: customerDetails.customer_phone
      };

      try {
        const response = await axios.post('https://whytefarms.com/api/payment', data);  // Use relative URL
        const { payUData, payuURL } = response.data;
  
        // Create a form dynamically to submit payment details to PayU endpoint
        const form = document.createElement('form');
        form.action = payuURL;
        form.method = 'POST';

        Object.keys(payUData).forEach((key) => {
          const input = document.createElement('input');
          input.type = 'hidden';
          input.name = key;
          input.value = payUData[key];
          form.appendChild(input);
        });
  
        // Append form to the document body and submit it
        document.body.appendChild(form);
        form.submit();
        subscriptionReasonUpdate()
      } catch (error) {
        console.error('Payment Error:', error);
        // Handle payment error (e.g., show an error message to the user)
      }
    }
  };


  const [uniqueOrders, setUniqueOrders] = useState([]);

  const toggleSubscriptionStatus = async (docId, currentStatus,subscription_id) => {
    const newStatus = currentStatus === "1" ? "0" : "1";
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
  
    try {
      if (newStatus === "1") {
        await db.collection("subscriptions_data")
          .doc(docId)
          .update({
            status: newStatus,
            next_delivery_date: moment(tomorrow).format('YYYY-MM-DD'),
            resume_date: new Date(tomorrow)
          });
      } else {
        const { value: date } = await Swal.fire({
          title: "Select resume date",
          input: "date",
          showCancelButton: true,
          didOpen: () => {
            const today = (new Date()).toISOString().split("T")[0];
            Swal.getInput().min = today;
          }
        });
  
        if (date) {
          const resumeDate = new Date(date);
          const nextDeliveryDate = moment(resumeDate).add(1, 'days').format('YYYY-MM-DD');
  
          await db.collection("subscriptions_data")
            .doc(docId)
            .update({
              status: newStatus,
              resume_date: resumeDate,
              next_delivery_date: nextDeliveryDate
            });
        } else {
          const today = new Date();
          const defaultNextDeliveryDate = moment(today).add(1, 'days').format('YYYY-MM-DD');
  
          await db.collection("subscriptions_data")
            .doc(docId)
            .update({
              status: newStatus,
              resume_date: today,
              next_delivery_date: defaultNextDeliveryDate
            });
        }
      }
  
      // Record the activity in customer_activity collection
      await db.collection('customer_activities').add({
        customer_id: customerDetails.customer_id, 
        action: `Subscription status change`,
        subscription_id: sidBU,
        description: `Subscription ${subscription_id} status changed by  ${ customerDetails.customer_name}, and the status is ${newStatus}`,
        date: new Date(),
        created_date: new Date(),
        customer_phone: customerDetails.customer_phone,
        customer_name: customerDetails.customer_name,
        user: customerDetails.customer_name,
        object: "Subscription status change",
        platform:'webApp'
      });
  
      setSubscriptions((prevSubscriptions) =>
        prevSubscriptions.map((subscription) =>
          subscription.id === docId
            ? { ...subscription, status: newStatus }
            : subscription
        )
      );
    } catch (error) {
      console.error("Error updating subscription status:", error);
    }
  };
  

  const handleDeleteSubscription = async (docId) => {
    const confirmDeletion = window.confirm(
      "Are you sure you want to delete this subscription?"
    );
    if (confirmDeletion) {
      try {
        await db.collection("subscriptions_data").doc(docId).delete();
        
        // Record the activity in customer_activity collection
        await db.collection('customer_activities').add({
        customer_id: customerDetails.customer_id, 
        subscription_id: sidBU,
        action:`Subscription deleted`,
        description: `Subscription ${docId} deleted by  ${ customerDetails.customer_name}`,
        date: new Date(),
        created_date: new Date(),
        customer_phone: customerDetails.customer_phone,
        customer_name: customerDetails.customer_name,
        user: customerDetails.customer_name,
        object: "Subscription deleted",
        platform:'webApp'
      });
  
        // Update local state to remove the deleted subscription
        setSubscriptions((prevSubscriptions) =>
          prevSubscriptions.filter((subscription) => subscription.id !== docId)
        );
  
        fetchSubscriptionCount(customerDetails.customer_id);
      } catch (error) {
        console.error("Error deleting subscription:", error);
        alert("Failed to delete subscription");
      }
    }
  };
  

  const [editingSubscription, setEditingSubscription] = useState(null);
  const [customer_idBU, setCustomer_idBU] = useState("");
  const [productBU, setProductBU] = useState("");
  const [sidBU, setSidBU] = useState("");
  const [quantity, setQuantity] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [shoWEditSubscription, setShoWEditSubscription] = useState(false);
  const [showEditBulkQuantity, setShowEditBulkQuantity] = useState(false);
  const [loadEditSubModal, setLoadEditSubModal] = useState(false);
  const [loadAddVacation, setLoadAddVacation] = useState(false);

  const handleEditClick = (subscription) => {
    setShowEditBulkQuantity(!showEditBulkQuantity)
    setEditingSubscription(subscription);
    setEditingSubscriptionQuantity(null);
    setCustomer_idBU(subscription.customer_id);
    setProductBU(subscription.product_name);
    setSidBU(subscription.subscription_id);
    setQuantity(subscription.quantity);
    setStartDate(moment(subscription.start_date.toDate()).format("YYYY-MM-DD"));
    setEndDate(
      subscription.end_date
        ? moment(subscription.end_date.toDate()).format("YYYY-MM-DD")
        : ""
    );
  };

  const [editingSubscriptionQuantity, setEditingSubscriptionQuantity] =
    useState(null);
  const [subscriptionID, setSubscriptionID] = useState("");

  const updateQuantity = (subscription) => {

    setSelectedSub(subscription);
    setLoadEditSubModal(true);
    setShoWEditSubscription(!shoWEditSubscription)
    setEditingSubscriptionQuantity(subscription);
    setSubscriptionID(subscription.id);
    setQuantity(subscription.quantity);
    setEditingSubscription(null);
    setUpdateAdvanceOrder(true);
  };

  const handleUpdateQuantity = async (event) => {
    event.preventDefault();

    try {
      const customersRef = db
        .collection("subscriptions_data")
        .doc(subscriptionID);
      await customersRef.update({ quantity: Number(quantity) });

      alert("Subscription updated successfully");
      setEditingSubscription(null);
    } catch (error) {
      console.error("Error updating subscription: ", error);
      alert("Error updating subscription");
    }
  };

  const handleSaveUP = async (event) => {
    event.preventDefault();
    try {
      const existingUpdates = await db
        .collection("bulk_update_quantity")
        .where("customer_id", "==", editingSubscription.customer_id)
        .where("startDate", "<=", new Date(endDate))
        .where("endDate", ">=", new Date(startDate))
        .get();

      if (!existingUpdates.empty) {
        alert("Updates for these dates already exist for the same customer.");
        return;
      }

      await db.collection("bulk_update_quantity").add({
        customer_id: customer_idBU,
        date: new Date(),
        product_name: productBU,
        quantity: Number(quantity),
        status: "1",
        subscription_id: sidBU,
        startDate: new Date(startDate),
        endDate: new Date(endDate),
      });
       // Record the activity in customer_activity collection
       await db.collection('customer_activities').add({
        customer_id: customerDetails.customer_idBU, 
        subscription_id: sidBU,
        action:`Bulk Update`,
        description: `Subscription Bulk Update BY  ${ customerDetails.customer_name} and quantity is ${quantity} `,
        date: new Date(),
        created_date: new Date(),
        customer_phone: customerDetails.customer_phone,
        customer_name: customerDetails.customer_name,
        user: customerDetails.customer_name,
        object: "Bulk Update",
        platform:'webApp'
      });
      alert("Subscription updated successfully");
      setEditingSubscription(null);
      
    } catch (error) {
      console.error("Error updating subscription: ", error);
      alert("Error updating subscription");
    }
  };

  const handlePreferenceClick = async (preference) => {
    
    const createdDate = new Date();

    try {
      
      const existingPreferenceQuery = await db
        .collection("delivery_preference")
        .where("customer_id", "==", customerDetails.customer_id)
        .get();

      if (!existingPreferenceQuery.empty) {
        
        const existingPreferenceDoc = existingPreferenceQuery.docs[0];
        await existingPreferenceDoc.ref.update({
          delivery_mode: preference,
          additional_instruction: "",
          selected_days: [],
          status: "1",
          customer_phone: customerDetails.customer_phone,
          customer_id: customerDetails.customer_id,
          customer_name: customerDetails.customer_name,
          updated_date: new Date(),
        });
        alert("Preference updated successfully");
        setDeliveryPreference(preference);
      } else {
        
        await db.collection("delivery_preference").add({
          delivery_mode: preference,
          additional_instruction: "",
          selected_days: [],
          status: "1",
          customer_phone: customerDetails.customer_phone,
          customer_id: customerDetails.customer_id,
          customer_name: customerDetails.customer_name,
          updated_date: new Date(),
          created_date: new Date(),
        });
        setDeliveryPreference(preference);
        alert("Preference added successfully");
      }
    } catch (error) {
      console.error("Error updating preference: ", error);
    }
  };

  const handleFutureDateChange = (date) => {
    setFutureDate(date);
    fetchAdvanceOrders(date);
  };

  const [quantityBulk, setQuantityBulk] = useState('');
  const [editingOrder, setEditingOrder] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState({});
  const [cid, setCid] = useState('');
  const [sid, setSid] = useState('');
  const [pname, setPname] = useState('');
  const [insufficientWalletBalance, setInsufficientWalletBalance] = useState(false);

  const updateQuantityBulk = (order) => {
    setCid(order.customer_id);
    setSid(order.subscription_id);
    setPname(order.product_name);
    setEditingOrder(true);
    setSelectedProduct(order);

  };

  const handleAddEntry = async (e) => {
    const totalAmount = (selectedProduct.price * quantityBulk)
    e.preventDefault();
    const createdDate = new Date().toISOString();
    const futureDateEnd = new Date(futureDate);
    futureDateEnd.setHours(23, 59, 59, 999);

    if (totalAmount > customerDetails.wallet_balance) {
      setInsufficientWalletBalance(true)
    } else {
      setInsufficientWalletBalance(false)
      try {
        const getBulkQty = await getBulkQuantityForUpdate(futureDate, sid);

        if (getBulkQty == null) {
          
          await db.collection('bulk_update_quantity').add({
            quantity: parseInt(quantityBulk),
            date: new Date(futureDate),
            delivery_date: moment(futureDate).format('YYYY-MM-DD'),
            status: "1",
            subscription_id: sid,
            customer_id: cid,
            product_name: pname,
            created_date: new Date(),
            updated_date: new Date()
          });
          db.collection("customer_activities").add({
            'customer_phone': customerDetails.customer_phone,
            'customer_id': customerDetails.customer_id,
            'customer_name': customerDetails.customer_name,
            'customer_address': customerDetails.customer_address,
            'hub_name': customerDetails.hub_name,
            'delivery_exe_id': customerDetails.delivery_exe_id,
            'user': customerDetails.customer_name,
            'object': "Subscription",
            'action': "Change Quantity (Calendar)",
            'description': `Quantity changed to ${quantityBulk} for ${moment(futureDate).format('DD-MM-YYYY')} in calendar for subscription ID: ${sid} by ${customerDetails.customer_name}`,
            'platform':'webApp',
            'date': new Date(),
            'created_date': new Date()
          }).then(() => {

          });
          
        } else {
          
          const collRef = collection(db, 'bulk_update_quantity');
          const q = query(
            collRef,
            where('subscription_id', '==', sid),
            where('delivery_date', '==', moment(futureDate).format('YYYY-MM-DD')),
            
          );

          const querySnapshot = await getDocs(q);

          if (!querySnapshot.empty) {
            const doc = querySnapshot.docs[0]; 
            await db.collection('bulk_update_quantity').doc(doc.id).update({
              quantity: parseInt(quantityBulk),
              date: new Date(futureDate),
              delivery_date: moment(futureDate).format('YYYY-MM-DD'),
              status: "1",
              customer_id: cid,
              product_name: pname,
              updated_date: new Date()
            });
            db.collection("customer_activities").add({
              'customer_phone': customerDetails.customer_phone,
              'customer_id': customerDetails.customer_id,
              'customer_name': customerDetails.customer_name,
              'customer_address': customerDetails.customer_address,
              'hub_name': customerDetails.hub_name,
              'delivery_exe_id': customerDetails.delivery_exe_id,
              'user': customerDetails.customer_name,
              'object': "Subscription",
              'action': "Change Quantity (Calendar)",
              'description': `Quantity changed to ${quantityBulk} for ${moment(futureDate).format('DD-MM-YYYY')} in calendar for subscription ID: ${doc.subscription_id} by ${customerDetails.customer_name}`,
              'platform':'webApp',
              'date': new Date(),
              'created_date': new Date()
            }).then(() => {

            });
            
          }
        }

        setEditingOrder(false);
        setQuantityBulk('');
        fetchAdvanceOrders(futureDate);
      } catch (error) {
        console.error('There was an error adding/updating the entry!', error);
      }
    }
  };

  const onCloseDialog = () => {

    fetchSubscriptions(customerDetails.customer_id);
    setLoadEditSubModal(false);
  }

  // const updateNextDeliveryDateForAllSubscription = async (vacation_end_date) => {
    
  //   // const next_delivery_date = new Date(vacation_end_date);
  //   // next_delivery_date.setDate(next_delivery_date.getDate() + 1);

  //   // for (const subscription of subscriptions) {

  //   //   const docId = subscription.id;
  //   //   const docRef = db.collection('subscriptions_data').doc(docId);
  //   //   if (new Date(subscription.start_date.toDate()).setHours(0, 0, 0, 0) > new Date()) {
  //   //     await docRef.update({
  //   //       next_delivery_date: moment(subscription.start_date.toDate()).format('YYYY-MM-DD')
  //   //     });
  //   //   } else {
  //   //     await docRef.update({
  //   //       next_delivery_date: moment(next_delivery_date).format('YYYY-MM-DD')
  //   //     });
  //   //   }


  //   // }
  // }
  const addVacation = () => {
    setLoadAddVacation(true);
  }

  const onCloseVacationDialog = () => {

    fetchVacations(customerDetails.customer_id);
    setLoadAddVacation(false);
  }

  const deleteVacation = (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        db.collection("customers_vacation").doc(id).delete().then(() => {
          db.collection("customer_activities").add({
            'customer_phone': customerDetails.customer_phone,
            'customer_id': customerDetails.customer_id,
            'customer_name': customerDetails.customer_name,
            'customer_address': customerDetails.customer_name,
            'hub_name': customerDetails.hub_name,
            'delivery_exe_id': customerDetails.delivery_exe_id,
            'user': customerDetails.customer_name,
            'object': "delete vacation",
            'action': "delete vacation",
            'description': `Vacation has been deleted by ${customerDetails.customer_name}`,
            //'description': description,   
            'platform':'webApp',
            'date': new Date(),
            'created_date': new Date()
          }).then(() => {
          });
          // updateNextDeliveryDateForAllSubscription(new Date());
        })

      }
    })

  }

  return (
    <>

      <div class="container-fluid profile-top-section">


        <div class="container" style={{ marginTop: '10rem' }}>
          <Navbar />
          <div class="row mt-5">
            <div class="col-md-4 col-lg-4 col-sm-12">
              <div class="profile-btn theme-color">
                Account Details
                <div class="all-btn-profile">
                  <br />
                  <button
                    class={`subscription-button ${loadProfile ? 'active' : ''}`}
                    onClick={() => loadscreen("profile")}
                  >
                    <div class="left-content">
                      <i class="fas fa-bell"></i>
                      Profile
                    </div>
                  </button>
                  <button
                    class={`subscription-button ${loadSubs ? 'active' : ''}`}
                    onClick={() => loadscreen("subscriptions")}
                  >
                    <div class="left-content">
                      <i class="fas fa-bell"></i>
                      Subscription
                    </div>
                    <div class="badge number-badge">{subscriptionCount}</div>
                  </button>
                  <button
                    class={`subscription-button ${loadWallet ? 'active' : ''}`}
                    onClick={() => loadscreen("wallet")}
                  >
                    <div class="left-content">
                      <i class="fas fa-wallet"></i>
                      WALLET
                    </div>
                    <div class="badge number-badge">
                      {customerDetails.wallet_balance}
                    </div>
                  </button>
                  <button class="subscription-button">
                    <div class="left-content">
                      <i class="fas fa-gift"></i>
                      {customerDetails.referral_code}
                    </div>
                  </button>
                  <button
                    class={`subscription-button ${loadOrders ? 'active' : ''}`}
                    onClick={() => loadscreen("orders")}
                  >
                    <div class="left-content">
                      <i class="fas fa-shopping-basket"></i>
                      ORDERS
                    </div>
                    <div class="badge number-badge">{ordersCount}</div>
                  </button>
                  <button
                    class={`subscription-button ${loadCalendar ? 'active' : ''}`}
                    onClick={() => loadscreen("calendar")}
                  >
                    <div class="left-content">
                      <i class="fas fa-shopping-basket"></i>
                      Calendar
                    </div>
                  </button>
                  <button
                    class={`subscription-button ${loadDeliveryPref ? 'active' : ''}`}
                    id="Section5"
                    onClick={() => loadscreen("delivery_preference")}
                  >
                    <div class="left-content">
                      <i class="fas fa-shopping-basket"></i>
                      Delivery Preference
                    </div>
                  </button>
                  <button
                    class={`subscription-button ${loadVacations ? 'active' : ''}`}
                    onClick={() => loadscreen("vacations")}
                  >
                    <div class="left-content">
                      <i class="fa fa-plane"></i>
                      Vacations
                    </div>
                    <div class="badge number-badge">{vacationCount}</div>
                  </button>
                </div>
              </div>
            </div>
            <div class="col-md-8 col-lg-8 col-sm-12 mb-5">
              {loadProfile && (
                <div className="user-profile-section">
                  <div className="user-header">
                    <div className="item-profile theme-color">My Profile</div>
                    {loading && (
                      <div className="loader-overlay">
                        <div className="">
                          <img style={{ height: "6rem" }} src="images/loader.gif" alt="Loading..." />
                        </div>
                      </div>
                    )}
                    <div className="item-save">
                      {edit ? (
                        <>
                          {" "}
                          <button
                            type="button"
                            className="subscribe-button"
                            onClick={handleSave}
                          >
                            Save
                          </button>
                        </>
                      ) : (
                        <>
                          <button
                            type="button"
                            className="subscribe-button"
                            onClick={() => setEdit(true)}
                          >
                            Edit
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="user-image">
                    <img
                      src="images/user.png"
                      style={{ width: "130px" }}
                      alt="User"
                    />
                    <div className="left-content">
                      {/* <i className="fas fa-gift"></i> */}
                      +91 - {customerDetails.customer_phone}
                    </div>
                  </div>

                  <div className="row">
                    <div className="mb-3 mt-5 col-md-6">
                      {edit ? (
                        <>
                          {" "}
                          <input
                            type="text"
                            placeholder="Your Full Name *"
                            className="form-control br"
                            name="customer_name"
                            value={customerDetails.customer_name}
                            onChange={handleChange}
                          />
                        </>
                      ) : (
                        <>
                          {" "}
                          <input
                            type="text"
                            placeholder="Your Full Name *"
                            className="form-control br"
                            name="customer_name"
                            value={customerDetails.customer_name}
                            onChange={handleChange}
                            disabled
                          />
                        </>
                      )}
                    </div>
                    <div className="mb-3 col-md-6 mt-5">
                      {edit ? (
                        <>
                          {" "}
                          <input
                            type="email"
                            placeholder="Email ID *"
                            className="form-control br"
                            name="customer_email"
                            value={customerDetails.customer_email || ""}
                            onChange={handleChange}
                          />
                        </>
                      ) : (
                        <>
                          {" "}
                          <input
                            type="email"
                            placeholder="Email ID *"
                            className="form-control br"
                            name="customer_email"
                            value={customerDetails.customer_email || ""}
                            onChange={handleChange}
                            disabled
                          />
                        </>
                      )}
                    </div>
                  </div>

                  <div className="row">
                    <div className="mb-3 col-md-6">
                      {edit ? (
                        <>
                          {" "}
                          <input
                            type="tel"
                            placeholder="Alternate Number"
                            className="form-control br"
                            name="alt_phone"
                            value={customerDetails.alt_phone}
                            onChange={handleChange}
                          />
                        </>
                      ) : (
                        <>
                          {" "}
                          <input
                            type="text"
                            placeholder="Alternate Number"
                            className="form-control br"
                            name="alt_phone"
                            value={customerDetails.alt_phone}
                            onChange={handleChange}
                            disabled
                          />
                        </>
                      )}
                    </div>
                    <div className="mb-3 col-md-6">
                      {edit ? (
                        <>
                          <Form.Select
                            name="hub_name"
                            className="form-control"
                            style={{ height: "2.83rem" }}
                            value={customerDetails.hub_name || ""}
                            onChange={handleChange}
                          >
                            <option value="">Select Hub</option>
                            {hubs.map((hub) => (
                              <option key={hub} value={hub}>
                                {hub}
                              </option>
                            ))}
                          </Form.Select>
                        </>
                      ) : (
                        <>
                          <Form.Select
                            name="hub_name"
                            className="form-control"
                            style={{ height: "2.83rem" }}
                            value={customerDetails.hub_name || ""}
                            onChange={handleChange}
                            disabled
                          >
                            <option value="">Select Hub</option>
                            {hubs.map((hub) => (
                              <option key={hub} value={hub}>
                                {hub}
                              </option>
                            ))}
                          </Form.Select>
                        </>
                      )}
                    </div>
                    <div className="mb-3 col-md-6">
                      {edit ? (
                        <>
                          <Form.Select
                            name="location"
                            className="form-control"
                            style={{ height: "2.85rem" }}
                            value={customerDetails.location || ""}
                            onChange={handleChange}
                          >
                            <option value="">Select Location</option>
                            {locations.map((location) => (
                              <option key={location} value={location}>
                                {location}
                              </option>
                            ))}
                          </Form.Select>
                        </>
                      ) : (
                        <>
                          <Form.Select
                            name="location"
                            className="form-control"
                            style={{ height: "2.85rem" }}
                            value={customerDetails.location || ""}
                            onChange={handleChange}
                            disabled
                          >
                            <option value="">Select Location</option>
                            {locations.map((location) => (
                              <option key={location} value={location}>
                                {location}
                              </option>
                            ))}
                          </Form.Select>
                        </>
                      )}
                    </div>
                    <div className="mb-3 col-md-6">
                      {edit ? (
                        <>
                          {" "}
                          <input
                            type="text"
                            placeholder="Address"
                            className="form-control br"
                            name="customer_address"
                            value={customerDetails.customer_address}
                            onChange={handleChange}
                          />
                        </>
                      ) : (
                        <>
                          {" "}
                          <input
                            type="text"
                            placeholder="Address"
                            className="form-control br"
                            name="customer_address"
                            value={customerDetails.customer_address}
                            onChange={handleChange}
                            disabled
                          />
                        </>
                      )}
                    </div>
                  </div>
                  {deliveryExecutive && (
                    <div className="delivery-details mt-3">
                      <div className="delivery-data">
                        <img
                          src="images/user2.png"
                          style={{ width: "80px", borderRadius: "50px" }}
                          alt="User"
                        />
                        <p
                          style={{
                            fontSize: "15px",
                            fontWeight: "600",
                            color: "#fff",
                          }}
                        >
                          {deliveryExecutive.first_name}
                          <br />
                          <a
                            href={`tel:${deliveryExecutive.phone_no}`}
                            style={{ color: "#fff", textDecoration: "none" }}
                          >
                            {deliveryExecutive.phone_no}
                          </a>
                          <br />
                          <p>(Delivery Executive)</p>
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              )}
              {loadDeliveryPref && (
                <div className="prefrences-section section" id="prefrence">
                  <p className="theme-color">Delivery Preference</p>
                  <div className="prefrences-section-img">
                    <div
                      className="preference-img"
                      onClick={() => handlePreferenceClick("Ring to Door Bell")}
                    >
                      <img src="img/prefer/5.png" className="prefer-img" alt="" />
                      <p class="text-center"
                        style={{
                          backgroundColor:
                            deliveryPreference === "Ring to Door Bell"
                              ? "#d2ab67"
                              : "",
                        }}
                      >
                        Ring to Door Bell
                      </p>
                    </div>
                    <div
                      className="preference-img"
                      onClick={() => handlePreferenceClick("In hand Delivery")}
                    >
                      <img src="img/prefer/4.png" className="prefer-img" alt="" />
                      <p class="text-center"
                        style={{
                          backgroundColor:
                            deliveryPreference === "In hand Delivery"
                              ? "#f28123"
                              : "",
                        }}
                      >
                        In hand Delivery
                      </p>
                    </div>
                    <div
                      className="preference-img"
                      onClick={() => handlePreferenceClick("No Preferences")}
                    >
                      <img src="img/prefer/6.png" className="prefer-img" alt="" />
                      <p class="text-center"
                        style={{
                          backgroundColor:
                            deliveryPreference === "No Preferences"
                              ? "#f28123"
                              : "",
                        }}
                      >
                        No Preferences
                      </p>
                    </div>
                    <div
                      className="preference-img"
                      onClick={() => handlePreferenceClick("Drop at the Door")}
                    >
                      <img src="img/prefer/7.png" className="prefer-img" alt="" />
                      <p class="text-center"
                        style={{
                          backgroundColor:
                            deliveryPreference === "Drop at the Door"
                              ? "#f28123"
                              : "",
                        }}
                      >
                        Drop at the Door
                      </p>
                    </div>
                  </div>
                </div>
              )}

              {loadSubs && (
                <>
                  <div
                    class="Subscription-section section theme-color"
                    id="Subscription"
                  >
                    My Subscription
                    <div class="table-section">
                      {loading && (
                        <div className="loader-overlay">
                          <div className="">
                            <img style={{ height: "6rem" }} src="images/loader.gif" alt="Loading..." />
                          </div>
                        </div>
                      )}
                      <br />
                      <table className="table table-hover">
                        <thead className="table-light">
                          <tr>
                            <th scope="col">Products</th>
                            <th scope="col">Price</th>
                            <th scope="col">Quantity</th>
                            <th scope="col">Status</th>
                            <th scope="col"> Type</th>
                            <th scope="col">Every</th>
                            <th scope="col">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {subscriptions.map((subscription, index) => (
                            <React.Fragment key={index}>
                              <tr>
                                <th scope="row">
                                  <img
                                    
                                    src={`images/${subscription.product_name.replace(/\\/g, '/').replace(/ /g, '%20')}.png`}
                                    width="40px"
                                    alt="Product"
                                  />
                                  <p className="product-name">
                                    {subscription.product_name}
                                    <br />
                                    {subscription.product_description}
                                  </p>
                                </th>
                                <td className="theme-color">
                                  &#8377;{subscription.price}
                                </td>
                                {subscription.subscription_type != "Custom" ? <td>{subscription.quantity}</td> : <td>-</td>}

                                <td>
                                  <label className="switch">
                                    <input
                                      type="checkbox"
                                      checked={subscription.status === "1"}
                                      onChange={() =>
                                        toggleSubscriptionStatus(
                                          subscription.id,
                                          subscription.status,
                                          subscription.subscription_id
                                        )
                                      }
                                    />
                                    <span className="slider round"></span>
                                  </label>
                                </td>
                                <td>{subscription.subscription_type}</td>
                                {subscription.subscription_type != "Custom" ? <td>{subscription.interval} day(s)</td> : <td>-</td>}
                                <td>
                                  <i
                                    className="fas fa-edit"
                                    style={{ paddingRight: "10px", cursor: 'pointer' }}
                                    onClick={() => updateQuantity(subscription)}
                                  ></i>
                                  
                                  {/* <i
                                    className="fas fa-trash"
                                    style={{ paddingRight: "10px", cursor: 'pointer' }}
                                    onClick={() =>
                                      handleDeleteSubscription(subscription.id)
                                    }
                                  ></i> */}
                                </td>
                              </tr>
                              {showEditBulkQuantity && editingSubscription &&
                                editingSubscription.id === subscription.id && (
                                  <tr>
                                    <td colSpan="7">
                                      <form onSubmit={handleSaveUP}>
                                        <div className="form-group">
                                          <label>Update Bulk Quantity</label>
                                          <input
                                            type="number"
                                            className="form-control"
                                            value={quantity}
                                            onChange={(e) => {
                                              const value = e.target.value;
                                              if (value === '' || value < 0) {
                                                setQuantity(0);
                                              } else {
                                                setQuantity(parseInt(value, 10)); 
                                              }
                                            }}
                                            required
                                            min="0"
                                            style={{ width: '60px' }} 
                                          />
                                        </div>
                                        <div className="form-group">
                                          <label>Start Date</label>
                                          <input
                                            type="date"
                                            className="form-control"
                                            value={startDate}
                                            onChange={(e) =>
                                              setStartDate(e.target.value)
                                            }
                                            required
                                          />
                                        </div>
                                        <div className="form-group">
                                          <label>End Date</label>
                                          <input
                                            type="date"
                                            className="form-control"
                                            value={endDate}
                                            onChange={(e) =>
                                              setEndDate(e.target.value)
                                            }
                                            required
                                          />
                                        </div>
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "end",
                                          }}
                                        >
                                          <button
                                            type="submit"
                                            className="btn btn-primary"
                                          >
                                            Update
                                          </button>
                                        </div>
                                      </form>
                                    </td>
                                  </tr>
                                )}
                            </React.Fragment>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </>
              )}
              {loadWallet && (
                   <div class="wallet-section section" id="wallet">
                   <div class="row">
                     <div>
                       <p class="theme-color">Wallet History </p>
                       <br />
                       <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                         <div>
                           <div>
                             <div class="recharge-section">
                               <p class="theme-color">ADD MONEY</p>
                               <p>
                                 <b>Select Quick Amount</b>
                               </p>
                               <div className="amount-select-btn mb-3">
                                 {[500, 1000, 1500, 2000].map((amount) => (
                                   <div className="item-save" key={amount}>
                                     <button
                                       type="button"
                                       className="btn btn-light theme-color btn-amount"
                                       onClick={() => handleQuickAmountSelect(amount)}
                                     >
                                       &#8377;{amount}
                                     </button>
                                   </div>
                                 ))}
                               </div>
                               <form>
                                 <div className="mb-3">
                                   <input
                                     type="text"
                                     placeholder="Enter Amount"
                                     className="form-control br"
                                     value={amount}
                                     required
                                     onChange={(e) => setAmount(e.target.value)}
                                   />
                                 </div>
                                 <div className="mb-3">
                                   <input
                                     type="text"
                                     placeholder="Email"
                                     className="form-control br"
                                     value={email}
 
                                     onChange={(e) => setEmail(e.target.value)}
                                   />
                                 </div>
                               </form>
                               <div class="payment-method">
                                 <div>
                                   <div className="subscribe-button" style={{ backgroundColor: 'black', cursor: 'pointer' }} onClick={handleAddMoney}>
                                     <i class="fas fa-wallet" style={{ color: '#fff' }}></i>
                                     <span style={{ marginLeft: '5px' }}>Pay Online</span>
                                   </div>
                                 </div>
                               </div>
                             </div>
                           </div>
                         </div>
                         <br />
                         <div>
                           <div class="wallet-history">
                             <table class="table table-light">
                               <thead>
                                 <tr>
                                   <th scope="col">Transaction Date</th>
                                   <th scope="col">Type</th>
                                   <th scope="col">Reason</th>
                                   <th scope="col">Amount</th>
                                   {/* <th scope="col">Ledger Balance</th> */}
                                 </tr>
                               </thead>
                               <tbody>
                                 {walletHistory.slice(0, visibleRecords).map((entry, index) => (
                                   <tr key={index}>
                                     <td className="theme-color">
                                       {moment(entry.created_date.toDate()).format(
                                         "DD/MM/YYYY"
                                       )}
                                     </td>
                                     <td
                                       style={{
                                         color:
                                           entry.type === "Credit" ? "green" : "red",
                                       }}
                                     >
                                       {entry.type}
                                     </td>
                                     <td className="theme-color">{entry.reason}</td>
                                     <td className="theme-color">
                                       &#8377;{entry.amount}
                                     </td>
                                     
                                   </tr>
                                 ))}
                               </tbody>
                             </table>
                             {walletHistory.length > visibleRecords && (
                               <button className="btn btn-dark mt-3" onClick={loadMore}>
                                 Load More
                               </button>
                             )}
                           </div>
                         </div>
                       </div>
 
                     </div>
 
                   </div>
                 </div>
              )}
              {loadOrders && (
                <>
                  <div className="wallet-section section" id="order">
                    <div className="row">
                      <div className="col-md-7 col-lg-7 col-sm-12">
                        <p className="theme-color">Orders</p>
                        <div className="wallet-history">
                          <table className="table table-light">
                            <thead>
                              <tr>
                                <th scope="col">Date</th>
                                <th scope="col">Order ID.</th>
                                <th scope="col">Delivery By</th>
                                <th scope="col">Amount</th>
                              </tr>
                            </thead>
                            <tbody>
                              {uniqueOrders.slice(0, visibleOrders).map((entry, index) => (
                                <tr
                                  key={index}
                                  onClick={() => handleOrderClick(entry)}
                                  style={{ cursor: 'pointer' }}
                                >
                                  <th scope="row">
                                    {moment(
                                      entry.delivery_timestamp.toDate()
                                    ).format("DD/MM/YYYY")}
                                  </th>
                                  <td className="theme-color">
                                    {entry.order_id}
                                  </td>
                                  <td className="theme-color">
                                    {entry.hub_name}
                                  </td>
                                  <td className="theme-color">
                                    &#8377;{entry.total_amount}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                          {uniqueOrders.length > visibleOrders && (
                            <button className="btn btn-dark mt-3" onClick={loadMoreOrders}>
                              Load More
                            </button>
                          )}
                        </div>
                      </div>
                      <div className="col-md-5 col-lg-5 col-sm-12">
                        {selectedOrder && (
                          <div className="recharge-section mt-3">
                            <p className="theme-color">Order Details</p>
                            <div className="deliver-detail mb-3">
                              <div className="deliverd">
                                <b>Delivered By:</b>
                                <br />
                                {selectedOrder.hub_name}
                              </div>
                              <div className="deliverd">
                                <b>Delivered On:</b>
                                <br />
                                {moment(
                                  selectedOrder.delivery_timestamp.toDate()
                                ).format("MM/DD/YYYY")}
                              </div>
                            </div>
                            <div className="deliver-detail mb-3">
                              <div className="deliverd">
                                <b>Delivered To:</b>
                                <br />
                                {selectedOrder.delivering_to}
                              </div>
                              <div className="deliverd">
                                <p
                                  style={{
                                    backgroundColor:
                                      selectedOrder.status === "1"
                                        ? "#198754"
                                        : selectedOrder.status === "0"
                                          ? "#007bff"
                                          : "#dc3545",
                                  }}
                                >
                                  {selectedOrder.status === "1"
                                    ? "Delivered"
                                    : selectedOrder.status === "0"
                                      ? ""
                                      : "Cancelled"}
                                </p>
                              </div>
                            </div>
                            <table className="table table-light">
                              <thead>
                                <tr>
                                  <th scope="col">Product</th>
                                  <th scope="col">Qty</th>
                                  <th scope="col">Unit</th>
                                  <th scope="col">Total</th>
                                </tr>
                              </thead>
                              <tbody>
                                {selectedOrder.products &&
                                  selectedOrder.products.length > 0 ? (
                                  selectedOrder.products.map(
                                    (product, index) => (
                                      <tr key={index}>
                                        <th scope="row">
                                          {/* <img src={product.image} style={{ width: '10px' }} alt={product.name} /> */}
                                          {product.name}
                                        </th>
                                        <td className="theme-color">
                                          {product.quantity}
                                        </td>
                                        <td className="theme-color">
                                          {product.package_unit}
                                        </td>
                                        <td className="theme-color">
                                          &#8377;{product.price}
                                        </td>
                                      </tr>
                                    )
                                  )
                                ) : (
                                  <tr>
                                    <td colSpan="4">
                                      No products found for this order
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </>
              )}
              {loadCalendar && (
                <>
                  {loading && (
                    <div className="loader-overlay">
                      <div className="">
                        <img style={{ height: "6rem" }} src="images/loader.gif" alt="Loading..." />
                      </div>
                    </div>
                  )}
                  <div className="wallet-section section" id="order">
                    <div className="row">
                      <div className="col-md-12 col-lg-12 col-sm-12">
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                          }}
                        >
                          <p className="theme-color">Calendar</p>
                          <div className="datepicker-container">
                            <label
                              className="datepicker-label"
                              style={{ marginRight: "10px" }}
                            >
                              Delivery Date :{" "}
                            </label>
                            <DatePicker
                              selected={futureDate}
                              onChange={handleFutureDateChange}
                              selectsStart
                              startDate={futureDate}
                              minDate={futureDate}
                              //endDate={endDate}
                              dateFormat="dd-MM-yyyy" 
                              className="datepicker-input"
                              placeholderText="Delivery date"
                            />
                          </div>
                        </div>

                        <br />
                        <div className="wallet-history">
                          <table className="table table-light">
                            <thead>
                              <tr>
                                <th scope="col">Delivery Date</th>
                                <th scope="col">Product Name</th>
                                <th scope="col">Quantity</th>
                                <th scope="col">Every</th>
                                <th scope="col">Price</th>
                              </tr>
                            </thead>
                            <tbody>
                              {advanceOrders.map((order, index) => (
                                <React.Fragment key={index}>
                                  <tr key={order.subscription_id}>
                                    <th scope="row">
                                      {moment(futureDate).format("YYYY-MM-DD")}
                                    </th>
                                    <td className="theme-color">{order.product_name}</td>
                                    <td className="theme-color">
                                      <button
                                        className="btn btn-sm btn-warning rounded"
                                        onClick={() => updateQuantityBulk(order)}
                                      >
                                        -
                                      </button>
                                      <span style={{ margin: "0 10px" }}>{order.quantity}</span>
                                      <button
                                        className="btn btn-sm btn-warning rounded"
                                        onClick={() => updateQuantityBulk(order)}
                                      >
                                        +
                                      </button>
                                    </td>
                                    
                                    <td className="theme-color">
                                      {order.subscription_type === "Custom" ? "-" : `${order.interval} day(s)`}
                                    </td>
                                    <td className="theme-color">&#8377;{order.price}</td>
                                  </tr>
                                  {editingOrder && (
                                    <tr>
                                      <td colSpan="7">
                                        <form onSubmit={handleAddEntry}>
                                          <div className="form-group">
                                            <label>Update Quantity</label>
                                            <input
                                              type="number"
                                              className="form-control"
                                              value={quantityBulk}
                                              onChange={(e) => setQuantityBulk(e.target.value)}
                                              required
                                              min="0"
                                            />
                                          </div>
                                          {insufficientWalletBalance && (<p style={{ color: "#dc3545", marginBottom: "1rem" }}>You don't have enough balance. Please recharge your wallet for the remaining {((selectedProduct.price * quantityBulk) - customerDetails.wallet_balance).toFixed(2)} balance.</p>)}
                                          <div style={{ display: "flex", justifyContent: "end" }}>
                                            <button type="submit" className="btn btn-primary">
                                              Add
                                            </button>
                                          </div>
                                        </form>
                                      </td>
                                    </tr>
                                  )}
                                </React.Fragment>
                              ))}
                            </tbody>
                          </table>
                        </div>

                      </div>
                      <div className="col-md-5 col-lg-5 col-sm-12"></div>
                    </div>
                  </div>
                </>
              )}
              {loadVacations && (
                <>
                  <div className="wallet-section section" id="order">
                    <div className="row">
                      <div>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                          <div>
                            <p className="theme-color">Vacations</p>
                          </div>
                          <div>
                            <div className="item-save">
                              <>
                                {" "}
                                <button
                                  type="button"
                                  className="subscribe-button"
                                  onClick={addVacation}
                                >
                                  Add Vacation
                                </button>
                              </>

                            </div>
                          </div>
                        </div>
                        <br />

                        <div className="wallet-history">
                          <table class="table">
                            <thead>
                              <tr>
                                <th class="pt-1 ps-0">
                                  Sr.No
                                </th>
                                <th class="pt-1">
                                  Start Date
                                </th>
                                <th class="pt-1">
                                  End Date
                                </th>
                                

                                <th class="pt-1">
                                  Created On
                                </th>
                                
                                <th class="pt-1">
                                  Delete
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {vacationData?.map(({ id, data }, index) => (
                                <>
                                  <tr key={id} >
                                    <td>
                                      {index + 1}
                                    </td>
                                    <td>
                                      {moment(data.start_date.toDate().toISOString()).format("DD-MM-YYYY")}
                                    </td>
                                    <td>
                                      {moment(data.end_date.toDate().toISOString()).format("DD-MM-YYYY")}
                                    </td>
                                    {/* <td>
                                                                        {data.source}
                                                                    </td> */}
                                    <td>
                                      {moment(data.created_date.toDate().toISOString()).format("DD-MM-YYYY")}
                                    </td>
                                    {/* <td>
                                                                        {moment(data.updated_date.toDate().toISOString()).format("DD-MM-YYYY")}
                                                                    </td> */}

                                    <td>
                                      <button class="fas fa-trash" onClick={() => deleteVacation(id)}></button>
                                    </td>
                                  </tr>
                                </>
                              ))}

                            </tbody>
                          </table>
                        </div>
                      </div>

                    </div>
                  </div>
                </>
              )}

            </div>
            {loadEditSubModal && <Dialog isOpen={loadEditSubModal} onClose={onCloseDialog} subscription={selectedSub} customerDetails={customerDetails}></Dialog>}
            {loadAddVacation && <AddVacationDialog isOpen={loadAddVacation} onClose={onCloseVacationDialog} customerDetails={customerDetails} subscriptionData={subscriptions} ></AddVacationDialog>}

          </div>
        </div>
        <br />
        <Footer />
      </div>

    </>
  );
}

export default Profile;
