import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import db from "../Firebase";

const SuccessPage = () => {
    const location = useLocation();
    const navigate = useNavigate(); // Hook for programmatic navigation
    const query = new URLSearchParams(location.search);
    const data = JSON.parse(query.get('data') || '{}');
    const amount = data.amount; // Extract the amount from the data object
    const customerId = data.customer_id; // Ensure the customer_id is part of the data object

    useEffect(() => {
        const updateWalletBalance = async () => {
            try {
                // Update Firestore wallet balance
                const docRef = db.collection('subscription_data').doc(customerId);

                const doc = await docRef.get();
                if (doc.exists) {
                    const currentData = doc.data();
                    const newWalletBalance = (currentData.wallet_balance || 0) + parseInt(amount);

                    await docRef.update({ wallet_balance: newWalletBalance });
                } else {
                    console.error('Document not found');
                }
            } catch (error) {
                console.error('Error updating wallet balance:', error);
            }
        };

        if (amount && customerId) {
            updateWalletBalance();
        }

        // Redirect immediately after processing
        navigate("/");
    }, [amount, customerId, navigate]);

    return null; // Do not render anything
};

export default SuccessPage;
